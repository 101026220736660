import {createStore} from 'vuex'
import Common from './api/common'
import {generateUserMenu} from './router';
import setting from "@/api/setting";
import {ArrToObj} from "@/js/common/utils";

const SubjectType = {
  ASSET: "资产",
  LIABILITY: "负债",
  RIGHTS: "权益",
  COST: "成本",
  INCOME: "损益",
  COMMON: "共同",
  NET_ASSETS: "净资产",
  REVENUE: "收入",
  EXPENSES: "费用"
};

export default createStore({
  state: {
    User: {
      name: '林永雄',
      desc: '执着于理想，纯粹于当下',
      email: 'geek@gson.cn',
      org: '吉簿云(株洲)科技有限公司',
      dept: '研发部',
      title: '高级软件工程师',
      location: '株洲市',
      avatar: '/images/avatar.png',
      roleInfo: {},
      checkoutList: [],
      modules: []
    },
    webConfig: {
      "name": "",
      "company": "",
      "loginLogo": "",
      "loginSlogan": "",
      "loginSubSlogan": "",
      "loginLeftBg": "",
      "loginLeftCh": "",
      "copyright": "",
      "icp": "",
      "homeLogo": "",
      "qrCode": "",
      "register": true,
      "experienceDays": 30,
      "notice": "",
      "about": ""
    },
    prod: false,
    currentAccountSets: {},
    myAccountSets: [],
    tabs: [{
      key: "Home",
      title: "首页"
    }],
    reportList: [],
    subjectType: [],
    menus: [],
    currentTab: 'Home',
    currencyMap: {},
    voucherWords: [],
    sliderCollapsed: false
  },
  mutations: {
    updateTabs(state, payload) {
      state.tabs = payload;
    },
    newTab(state, tab) {
      let find = state.tabs.find(val => val.key === tab.key);
      if (!find) {
        state.tabs.push(tab);
      }
      state.currentTab = tab.key;
    },
    changeTab(state, tabKey) {
      state.currentTab = tabKey;
    },
    updateCurrency(state, currencyMap) {
      state.currencyMap = currencyMap;
    },
    updateVoucherWords(state, voucherWords) {
      state.voucherWords = voucherWords;
    },
    updateWebConfig(state, webConfig) {
      state.webConfig = webConfig;
    },
    updateReportList(state, reportList) {
      state.reportList = reportList;
    },
    refreshTab(state, tab) {
      let find = state.tabs.findIndex(val => val.key === tab.key);
      if (find !== -1) {
        state.tabs.splice(find, 1);
      }
      setTimeout(() => {
        state.tabs.push(tab);
      })
      state.currentTab = tab.key;
    },
    closeTab(state, tabKey) {
      let find = state.tabs.findIndex(val => val.key === tabKey);
      if (find !== -1) {
        let next = Math.max(0, find - 1);
        state.currentTab = state.tabs[next].key;
        state.tabs.splice(find, 1);
      }
    },
    updateAccount(state, user) {
      state.User = user;
      state.currentAccountSets = user.accountSets;
      if (user.accountSets) {
        state.subjectType = user.accountSets.subjectCategory.map(val => SubjectType[val]);
      }
      state.myAccountSets = user.accountSetsList;
      state.menus = generateUserMenu(user.roleInfo?.astAdmin, user.roleInfo?.authorities, user.modules?.reduce((map, item) => {
        map[item.moduleKey] = item;
        return map;
      }, {}));
    },
    updateSliderCollapse(state, isShow) {
      setTimeout(() => {
        G.trigger('page_resize');
      }, 600);
      state.sliderCollapsed = isShow;
    },
    updateProd(state, isProd = false) {
      state.prod = isProd;
    }
  },
  actions: {
    init(context) {
      return new Promise((resolve, reject) => {
        Common.init().then(({data}) => {
          context.commit('updateAccount', data.user);
          context.commit('updateCurrency', data.currency);
          context.commit('updateProd', data.prod);
          context.commit('updateReportList', data.reportList);
          context.commit('updateWebConfig', data.webConfig);

          data?.user?.accountSets && context.dispatch('voucherWords');

          resolve(data)
        }).catch(({data, message}) => {
          data && context.commit('updateWebConfig', data.webConfig);
          reject();
        })
      });
    },
    updateCurrency(context) {
      setting.currency.list().then(({data}) => {
        context.commit('updateCurrency', ArrToObj(data, val => val.id));
      })
    },
    voucherWords(context) {
      setting.voucherWord.list().then(({data}) => {
        context.commit('updateVoucherWords', data);
      })
    }
  },
  getters: {
    currentTabKey: state => "currentTab:" + (state.currentAccountSets?.id || ''),
    /**
     * 本位币
     * @param state
     * @returns {unknown}
     */
    localCurrency: state => {
      return Object.values(state.currencyMap).find(val => val.localCurrency)
    },
    currencySelectData: state => {
      return [{
        id: 1,
        name: "综合本币"
      }, {
        id: 0,
        name: "所有币别"
      }, ...Object.values(state.currencyMap)]
    },
    simplyCurrencySelectData: state => {
      return Object.values(state.currencyMap)
    },
    tabMap: state => {
      const map = {};
      state.tabs.forEach(item => {
        map[item.key] = item;
      })
      return map;
    },
    modulesMap: state => {
      return state.User.modules?.reduce((map, item) => {
        map[item.moduleKey] = item;
        return map;
      }, {})
    },
    account: state => {
      return state.User;
    },
    checkoutList: state => {
      return state.User.checkoutList;
    },
    lastCheckoutDate: state => {
      return state.User.checkoutList?.filter(it => it.status !== 2).pop()?.checkDate || state.currentAccountSets.enableDate;
    },
    firstCheckoutDate: state => {
      let reverse = state.User.checkoutList.filter(val => val.status !== 2).reverse();
      if (reverse.length) {
        return reverse[0].checkDate;
      }
      return null;
    },
    checkoutSelect: state => {
      return state.User.checkoutList.map(row => {
        const m = row.checkMonth < 10 ? '0' + row.checkMonth : row.checkMonth;
        return {
          key: row.checkYear + '年' + m + "期",
          title: row.checkYear + '年' + m + "期",
        }
      });
    },
    sliderCollapsed: state => {
      return state.sliderCollapsed;
    },
    roleInfo: state => {
      return state.User.roleInfo;
    },
    authorities: state => {
      return state.User.roleInfo?.authorities || [];
    }
  }
})
